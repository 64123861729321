import { useEffect, useRef, useState } from "react";
import logBreadcrumbs from "src/utils/logBreadcrumbs";
import { useRegisterOnConsent } from "src/utils/hooks/useRegisterOnConsent";
import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { useExperimentConfig } from "../../experiment/useExperimentConfig";
import { useFeature } from "../../feature/useFeature";
import useSearch from "../../utils/hooks/useSearch";
import { subscribeToSlotRenderEndedEvents } from "./admanager";
import { type SlotConfigId, networkCode, slotConfigMap } from "./config";
import { getTargeting } from "./targeting";
import {
  GOOGLE_ADS_CONSENT_PURPOSES_REQUIRED,
  GOOGLE_ADS_LEGITIMATE_INTEREST_PURPOSES_REQUIRED,
  GOOGLE_ADS_SALE_CONSENT_REQUIRED,
  GOOGLE_ADS_SHARING_CONSENT_REQUIRED,
} from "./DisplayAd";

// Helpful docs for the GPT library used in this file:
// https://developers.google.com/doubleclick-gpt/reference

// Make sure that googletag.cmd exists as it may not have loaded yet.
window.googletag = window.googletag || {};
// When the GPT JavaScript is loaded, it looks through the cmd array and executes all the functions in order.
window.googletag.cmd = window.googletag.cmd || [];

// Ensure media.net's queue exists
window.mnjs = window.mnjs || {};
window.mnjs.que = window.mnjs.que || [];

type Props = {
  slotConfigId: SlotConfigId;
  onFilled?: () => void;
  onNotFilled?: () => void;
  className?: string;
};

export type AdUnitFillStatus = "initializing" | "filled" | "failedToFill";

export function TieredDisplayAd(props: Props) {
  const { searchResponse } = useSearch();
  const hasSearchResponse = !!searchResponse;

  const experimentConfig = useExperimentConfig();
  const adFillStrategy = useFeature("FillAds");
  const delayLoadingPrebid = useFeature("DelayPrebidUntilConsentReady");
  const [adUnitFillState, setAdUnitFillState] =
    useState<AdUnitFillStatus>("initializing");

  const { adUnitCode, divId, sizes, sizeMapping, tiers, adUnitSubVersion } =
    slotConfigMap[props.slotConfigId];

  const [tierIndex, setTierIndex] = useState(0);

  const hasDisplayAdConsent = useRegisterOnConsent(
    GOOGLE_ADS_CONSENT_PURPOSES_REQUIRED,
    GOOGLE_ADS_LEGITIMATE_INTEREST_PURPOSES_REQUIRED,
    GOOGLE_ADS_SALE_CONSENT_REQUIRED,
    GOOGLE_ADS_SHARING_CONSENT_REQUIRED
  );

  // Saving the slot is necessary so that if we want to unmount an ad
  // we can destroy the slot properly which is necessary to do if we want to
  // remount it later on (like the right rail ad).
  const [slot, setSlot] = useState<googletag.Slot>();
  const slotRef = useRef(slot);
  useEffect(() => {
    slotRef.current = slot;
  }, [slot]);

  const tierIndexRef = useRef(tierIndex);
  useEffect(() => {
    tierIndexRef.current = tierIndex;
  }, [tierIndex]);

  const tiersRef = useRef(tiers);
  useEffect(() => {
    tiersRef.current = tiers;
  }, [tiers]);
  const tier = tiersRef.current[tierIndex];

  function isGoogleTagPresent(): boolean {
    // Even though we stub window.googletag and window.googletag.cmd it's possible
    // that an ad blocker has removed them. So, before we use googletag we need
    // to make sure it is still present.
    const isGoogleTagPresent = !!(window.googletag && window.googletag.cmd);
    if (!isGoogleTagPresent) {
      setAdUnitFillState("failedToFill");
      sendAnalyticsNonInteractionEvent({
        category: "Ads",
        action: "Error",
        label: "initGpt: googletag.cmd missing",
      });
    }
    return isGoogleTagPresent;
  }

  useEffect(() => {
    const unsubscribe = subscribeToSlotRenderEndedEvents((event) => {
      const tiers = tiersRef.current;
      const tierIndex = tierIndexRef.current;
      const slot = event.slot;

      // Each use of this component attaches a new event listener on the pubads
      // object. We need to filter out any events that were not for this slot.
      if (divId !== slot.getSlotElementId() || !isGoogleTagPresent()) {
        return;
      }

      if (event.isEmpty) {
        window.googletag.destroySlots([slot]);
        // Cleanup after Google's mess and empty the ad container.
        // The ad container should always exist, but check to be sure.
        const adContainer = document.getElementById(divId);
        if (adContainer) {
          adContainer.innerHTML = "";
        }

        if (tierIndex > -1 && tierIndex < tiers.length - 1) {
          // Check if there's another tier level we can use to fill the ad slot.
          setTierIndex(tierIndex + 1);
        } else {
          // The tier list is exhausted and the slot didn't fill
          // so hide container from view.
          sendAnalyticsNonInteractionEvent({
            category: "Ads",
            action: "FailedToFill",
            label: `${props.slotConfigId}`,
          });
          setAdUnitFillState("failedToFill");
          props.onNotFilled?.();
        }
      } else {
        const tier = tiers[tierIndex];
        setAdUnitFillState("filled");
        props.onFilled?.();
        logBreadcrumbs("Ads", `Ad rendered: ${props.slotConfigId}:${tier}`);
        sendAnalyticsNonInteractionEvent({
          category: "Ads",
          action: "Gpt:Render",
          label: `${props.slotConfigId}:${tier}`,
        });
      }
    });

    return () => {
      // Remove the subscription to slotRenderEnded when we're done with the component.
      unsubscribe();
      // We want to make sure the slot is destroyed anytime it's unmounted so that if it
      // is remounted later the slot can be created properly.
      slotRef.current &&
        isGoogleTagPresent() &&
        window.googletag.destroySlots([slotRef.current]);
    };

    // Ignore exhaustive deps because we only want to run this once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onViewableListener = (
      event: googletag.events.ImpressionViewableEvent
    ) => {
      const slot = event.slot;

      if (slot === slotRef.current) {
        sendAnalyticsNonInteractionEvent({
          category: "Ads",
          action: "gpt:AdViewed",
          label: `${props.slotConfigId}:${tier}`,
        });
        window.googletag
          .pubads()
          .removeEventListener("impressionViewable", onViewableListener);
      }
    };

    window.googletag.cmd.push(() => {
      if (!isGoogleTagPresent()) return;

      window.googletag
        .pubads()
        .addEventListener("impressionViewable", onViewableListener);
    });

    return () => {
      // Clean up
      window.googletag.cmd.push(() => {
        window.googletag
          .pubads()
          .removeEventListener("impressionViewable", onViewableListener);
      });
    };
  }, [tier, props.slotConfigId]);

  useEffect(() => {
    if (!window.googletag?.cmd || !searchResponse) return;

    const onRequestListener = (event: googletag.events.SlotRequestedEvent) => {
      const slot = event.slot;
      const targetingInfo = getTargeting({
        searchResponse,
        experimentConfig,
        adFillStrategy,
        tier,
      });

      if (slot.getSlotElementId() === divId && tier === "High") {
        // convert object into Record<String, String>
        const dimensions = Object.entries(targetingInfo ?? {}).reduce<
          Record<string, string>
        >((acc, [key, value]) => {
          // conversion is necessary because not all values are strings
          acc[key] = String(value);
          return acc;
        }, {});

        sendAnalyticsNonInteractionEvent({
          category: "Ads",
          action: "Requested",
          label: `${props.slotConfigId}`,
          extraInfo: { dimensions: dimensions },
        });
      }
    };

    window.googletag.cmd.push(() => {
      window.googletag
        .pubads()
        .addEventListener("slotRequested", onRequestListener);
    });

    return () => {
      // Clean up
      window.googletag.cmd.push(() =>
        window.googletag
          .pubads()
          .removeEventListener("slotRequested", onRequestListener)
      );
    };
  }, [
    adFillStrategy,
    divId,
    experimentConfig,
    props.slotConfigId,
    searchResponse,
    tier,
  ]);

  useEffect(() => {
    // We can't request an ad until we have a search response because
    // we need to know the experiment config to determine experiment targeting
    // for the MobileWebRewrite2020Holdback experiment. We also can't request an
    // ad if googletag has been removed by an ad blocker.
    if (
      adUnitFillState !== "initializing" ||
      !searchResponse ||
      !isGoogleTagPresent()
    ) {
      return;
    }

    window.googletag.cmd.push(function () {
      if (!isGoogleTagPresent()) {
        // An ad blocker has likely removed googletag, so we can't continue.
        return;
      }

      // For the purposes of header bidding, we need to ensure the user has given consent
      // before defining the ad slot.
      if (!hasDisplayAdConsent) {
        return;
      }

      const tier = tiersRef.current[tierIndex];

      const targeting = getTargeting({
        searchResponse,
        experimentConfig,
        adFillStrategy,
        tier,
      });

      // Switch to a test namespace for adunits when in testing mode.
      const adUnitRoot =
        `/${networkCode}` + (adFillStrategy !== "default" ? "/ztest-0" : "");

      const slot = window.googletag
        .defineSlot(
          `${adUnitRoot}/${adUnitCode}/${tier}-${adUnitSubVersion}`,
          sizes,
          divId
        )
        .addService(window.googletag.pubads())
        .updateTargetingFromMap(targeting);

      // Responsively size ads based on viewport width
      if (sizeMapping) {
        slot.defineSizeMapping(sizeMapping);
      }

      setSlot(slot);

      if (tierIndex === 0) {
        // If we're requesting the first tier, then we have just started
        // another request chain to fill the ad slot.
        sendAnalyticsNonInteractionEvent({
          category: "Ads",
          action: "Requesting",
          label: `${props.slotConfigId}`,
        });
      }

      // Ensure that the Google bid requests are triggered only after the Media.net Prebid script executes.
      if (delayLoadingPrebid) {
        window.mnjs.que.push(() => {
          window.googletag.display(divId);
        });
      } else {
        window.googletag.display(divId);
      }
    });
    // Ignore exhaustive deps because even if location or search response changes,
    // we don't want to run hook again.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tierIndex, hasSearchResponse, adUnitFillState, hasDisplayAdConsent]);

  return (
    <div id={divId} className={props.className} data-testid="tieredDisplayAd" />
  );
}
