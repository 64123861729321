import { useIntl } from "react-intl";
import {
  type QueryKey,
  type QueryObserverResult,
  type UseQueryOptions,
  useQuery,
} from "@tanstack/react-query";
import { DEFAULT_MAP_ZOOM } from "src/components/Map/constants";
import type { GeocodedPlace } from "src/PrefetchData";
import type { HotelSortOption } from "src/domain/HotelsScreen/HotelsContext";
import { useMemo } from "react";
import type { ApiConfig } from "../../api/ApiConfig";
import { useApiConfig } from "../../api/ApiConfigProvider";
import type { HotelListResponse, HotelType } from "../../api/HotelListResponse";
import type { SupportedCurrencyCode } from "../currency";
import type { SupportedLanguageCode } from "../language";
import { hotelListEndpoint } from "../../api/endpoints";
import type {
  HotelsFilterState,
  StarRatingType,
} from "../../domain/HotelsScreen/filtersReducer";
import type { Checkable } from "../../domain/HotelsScreen/HotelFilter/FilterComponents/CheckboxList";
import {
  type RoomDetail,
  useHotelsContext,
} from "../../domain/HotelsScreen/HotelsContext";
import { DEFAULT_CHILD_AGE } from "../../domain/HotelsScreen/HotelSearch/constants";
import {
  type HotelViewModel,
  hotelsScreenAdapter,
} from "../../domain/HotelsScreen/hotelsScreenAdapter";
import { localeToLanguageCode } from "../conversions/languageCode";
import { FIFTEEN_MINUTES_IN_MILLISECONDS } from "../conversions/time";
import {
  useIsHotelsUrlDeeplink,
  useIsTripHotelsUrlDeeplink,
} from "./useNavigateToHotelsPage";
import useSearch, { type SearchPlace } from "./useSearch";
import useUser from "./useUser";

// https://rome2rio.atlassian.net/wiki/spaces/ENG/pages/2592210945/Rome2Rio+api+1.6+integrations#HotelList-endpoint-(POST-%2Fapi%2F1.6%2Fhotels%2FhotelList)
/**
 * a static call returns data from a R2R data package, without sending a further request to a partner. No dates are sent, prices will be indicative only.
 * a live call triggers a further request to a partner, with dates, and will return accurate pricing data. However - we have limits on how many of these calls we can make.
 */

// The hotels shown in the list should always reflect the hotel-pins visible on the map.
// This wrapper syncs the hotel list query search between the list view and the map view for the hotels experience.
// For example, when the map is panned to a different location and a hotels-search-on-map is initiated,
// we have to update the lng and lat query keys so that it will trigger a refetch for the hotel list as well.
export function useHotelList(
  staticOrLive?: "static" | "live",
  sortOptionOverride?: HotelSortOption
) {
  const { hotelListQueryParams, destination, sortOption } = useHotelsContext();
  const isHotelsDeeplink = useIsHotelsUrlDeeplink();
  const isTripsHotelsDeeplink = useIsTripHotelsUrlDeeplink();

  return useHotelListBase({
    ...hotelListQueryParams,
    lat: hotelListQueryParams.lat ?? destination?.lat,
    lng: hotelListQueryParams.lng ?? destination?.lng,
    canonicalName: destination?.canonicalName,
    googlePlaceId: (destination as SearchPlace)?.googlePlaceId,
    sortOption: sortOptionOverride ?? sortOption,
    staticOrLive:
      staticOrLive ?? (isHotelsDeeplink || isTripsHotelsDeeplink)
        ? "live"
        : "static",
  });
}

type Props = {
  staticOrLive?: "static" | "live";
  canonicalName?: GeocodedPlace["canonicalName"];
  lat?: number;
  lng?: number;
  googlePlaceId?: string;
  arrivalDate?: Date;
  departureDate?: Date;
  enabled?: boolean;
  roomDetails?: RoomDetail[];
  radius?: number;
  sortOption?: HotelSortOption;
  zoom?: number;
  options?: UseQueryOptions<
    HotelListResponse,
    unknown,
    HotelListResponse,
    QueryKey
  >;
  filters?: HotelsFilterState;
  selectedHotelId?: string;
};

const HOTEL_LIST_LENGTH_RENDERED = 36;

// Caution: This can be used to fetch hotels data that is not connected to the hotel pins on the map.
// Use independently of the hotels experience page.
export function useHotelListBase({
  staticOrLive = "static",
  options,
  enabled,
  zoom = DEFAULT_MAP_ZOOM,
  sortOption = "default",
  ...props
}: Props): {
  isLoading: boolean;
  error: Error | null;
  hotelListResponse?: HotelListResponse;
  hotelList: HotelViewModel[] | undefined;
  numDefaultFilteredHotels: number | undefined;
  refetch: () => Promise<QueryObserverResult<HotelListResponse, unknown>>;
} {
  const intl = useIntl();
  const { currencyCode } = useUser();
  const { highlightedHotelIdRef } = useHotelsContext();
  const config = useApiConfig();
  const languageCode = localeToLanguageCode(intl.locale);
  const { searchResponse } = useSearch();
  // Must have one of a name, lat/lng, or googlePlaceId to be searchable
  const hasLocation =
    props.canonicalName !== undefined ||
    (props.lat !== undefined && props.lng !== undefined) ||
    props.googlePlaceId !== undefined;

  const requestId = searchResponse?.request.requestId ?? ""; // The id of a successful searchResponse.
  const checkIn = staticOrLive === "live" ? props.arrivalDate : undefined;
  const checkOut = staticOrLive === "live" ? props.departureDate : undefined;

  const hotelsListResult = useQuery<HotelListResponse>({
    queryKey: getKeyHotelListQuery({
      canonicalName: props.canonicalName,
      lat: props.lat,
      lng: props.lng,
      googlePlaceId: props?.googlePlaceId,
      arrivalDate: checkIn,
      departureDate: checkOut,
      currencyCode: currencyCode,
      languageCode: languageCode,
      zoom: zoom,
      radius: props.radius,
      roomDetails: props.roomDetails,
      filters: props.filters,
      selectedHotelId: props.selectedHotelId,
    }),
    queryFn: async () => {
      const result = await hitHotelsListEndpoint(
        config,
        requestId,
        languageCode,
        currencyCode,
        props.canonicalName,
        props.lat,
        props.lng,
        props.googlePlaceId,
        checkIn,
        checkOut,
        zoom,
        props.radius,
        props.roomDetails,
        props.filters,
        props.selectedHotelId
      );

      highlightedHotelIdRef.current = undefined;
      return result;
    },
    enabled: !!(enabled && hasLocation && !!requestId),
    gcTime: FIFTEEN_MINUTES_IN_MILLISECONDS,
    staleTime: FIFTEEN_MINUTES_IN_MILLISECONDS,
  });

  // Memo to prevent the hotel list changing on every render
  const viewModel = useMemo(
    () =>
      hotelsListResult.data
        ? hotelsScreenAdapter(hotelsListResult.data, HOTEL_LIST_LENGTH_RENDERED)
        : undefined,
    [hotelsListResult.data]
  );

  const sortedHotels = useMemo(
    () => (viewModel ? sortHotels(viewModel.hotels, sortOption) : undefined),
    [viewModel, sortOption]
  );

  return {
    ...hotelsListResult,
    isLoading: hotelsListResult.status === "pending",
    error: hotelsListResult.error,
    hotelListResponse: hotelsListResult.data,
    hotelList: sortedHotels,
    numDefaultFilteredHotels: viewModel?.numDefaultFilteredHotels,
    refetch: hotelsListResult.refetch,
  };
}

function sortHotels(hotels: HotelViewModel[], sortOption: HotelSortOption) {
  if (sortOption === "priceLow") {
    return [...hotels].sort((a, b) => a.price.value - b.price.value);
  } else if (sortOption === "priceHigh") {
    return [...hotels].sort((a, b) => b.price.value - a.price.value);
  } else if (sortOption === "reviewHigh") {
    return [...hotels].sort((a, b) => b.reviewScore - a.reviewScore);
  } else {
    return hotels;
  }
}

async function hitHotelsListEndpoint(
  config: ApiConfig,
  requestId: string,
  languageCode: SupportedLanguageCode,
  currencyCode: SupportedCurrencyCode,
  canonicalName: string | undefined,
  lat: number | undefined,
  lng: number | undefined,
  googlePlaceId: string | undefined,
  arrivalDate?: Date,
  departureDate?: Date,
  zoom?: number,
  radius?: number,
  roomDetails?: RoomDetail[],
  filters?: HotelsFilterState,
  selectedHotelId?: string
): Promise<HotelListResponse> {
  const url = hotelListEndpoint(
    config,
    canonicalName,
    lat,
    lng,
    googlePlaceId,
    formatDateForHotelsEndpoint(arrivalDate),
    formatDateForHotelsEndpoint(departureDate),
    languageCode,
    currencyCode,
    zoom,
    radius
  );

  // Set price filters to undefined if currency changed otherwise search on refresh might find no results.
  if (filters?.userCurrency) {
    if (filters?.userCurrency !== currencyCode) {
      filters.priceLowerBound = undefined;
      filters.priceUpperBound = undefined;
      filters.selectPriceLower = undefined;
      filters.selectPriceUpper = undefined;
    }
  }

  const requestBody = {
    key: config.key,
    uid: config.uid,
    aqid: config.aqid,
    requestId: requestId,
    pos: lat && lng ? `${lat},${lng}` : undefined,
    languageCode,
    currencyCode,
    name: canonicalName,
    zoom,
    radius,
    googlePlaceId: googlePlaceId,
    debugFeatures: config.backendFeatures,
    debugExperiments: config.backendExperiments,
    flags: config.backendFlags,
    arrivalDate: formatDateForHotelsEndpoint(arrivalDate), //Can be undefined (we will return non live results in this case).
    departureDate: formatDateForHotelsEndpoint(departureDate), //Can be undefined (we will return non live results in this case).
    hotelRooms: formatRoomDetailsForRequest(roomDetails),
    ratings: formatRatingsForRequest(filters),
    minimumReviewScore: formatMinReviewScoreForRequest(filters),
    facilityCodes: filters?.amenities ?? [],
    maxPrice: formatMaxPriceForRequest(filters),
    minPrice: filters ? filters.selectPriceLower : undefined,
    hotelTypeCodes: formatPropertyTypesForRequest(filters),
    selectedHotelId: selectedHotelId,
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer-when-downgrade",
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error(
      `Failed to fetch hotels list: ${
        response.status
      }, ${await response.text()}`
    );
  }

  return await response.json();
}

// Convert dates to YYYY-MM-DD for hotels endpoint.
export function formatDateForHotelsEndpoint(date?: Date) {
  if (!date) return;

  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export type HotelsApiRoom = {
  numberOfAdults: number;
  childrensAges: (number | undefined)[];
}[];
export function formatRoomDetailsForRequest(
  roomDetails?: RoomDetail[]
): HotelsApiRoom | undefined {
  if (!roomDetails) return;
  return roomDetails?.map((room: RoomDetail) => {
    return {
      numberOfAdults: room.adults,
      childrensAges: room.children.map((child) =>
        child.age !== undefined ? child.age : DEFAULT_CHILD_AGE
      ),
    };
  });
}

function formatRatingsForRequest(filters?: HotelsFilterState) {
  if (!filters) return [];
  type starRatingType = keyof StarRatingType;
  const starRatings = Object.keys(filters.starRating).filter(
    (key) => filters.starRating[key as starRatingType] === true
  );
  return starRatings.map(Number);
}

function formatMinReviewScoreForRequest(filters?: HotelsFilterState) {
  if (!filters) return;
  type guestRatingType = keyof Checkable;
  const minReviewScore = Object.keys(filters.guestRating).find(
    (key) => filters.guestRating[key as guestRatingType] === true
  );
  return minReviewScore ? Number(minReviewScore) : undefined;
}

function formatPropertyTypesForRequest(filters?: HotelsFilterState) {
  if (!filters) return [];
  const checkedPropertyTypeIds = Object.keys(filters.propertyTypes)
    .filter(
      (key) => filters.propertyTypes[key as any as HotelType]?.checked === true
    )
    .map((code) => Number(code));

  return checkedPropertyTypeIds;
}

function formatMaxPriceForRequest(filters?: HotelsFilterState) {
  return filters?.selectPriceUpper === filters?.priceUpperBound
    ? undefined
    : filters?.selectPriceUpper;
}

export type GetKeyHotelListQueryProps = {
  canonicalName: string | undefined;
  lat: number | undefined;
  lng: number | undefined;
  googlePlaceId: string | undefined;
  arrivalDate: Date | undefined;
  departureDate: Date | undefined;
  currencyCode: string;
  languageCode: string;
  zoom: number | undefined;
  radius: number | undefined;
  roomDetails: RoomDetail[] | undefined;
  filters: HotelsFilterState | undefined;
  selectedHotelId: string | undefined;
};

export function getKeyHotelListQuery(props: GetKeyHotelListQueryProps) {
  return [
    props.canonicalName,
    props.lat,
    props.lng,
    props.googlePlaceId,
    props.arrivalDate,
    props.departureDate,
    props.currencyCode,
    props.languageCode,
    props.zoom,
    props.radius,
    props.roomDetails,
    props.filters,
    props.selectedHotelId,
  ];
}
