import { breakpoints } from "src/theme";

export const networkCode = "14589826";

export const SlotConfigIds = [
  "desktopColumn",
  "desktopColumn2",
  "mobileHeader",
  "rightRail",
  "rightRail2",
  "rightRailVariant",
  "rightRail2Variant",
  "routeScreen",
  "routeScreen2",
  "segmentScreen",
  "segmentScreenBanner",
  "segmentScreenTower",
  "segmentScreenBaseline",
  "singleMobile",
  "singleMobile2",
  "singleDesktop",
  "singleDesktop2",
  "desktopInlineSerpAd",
  "inlineSegmentAd",
  "interstitialAd",
] as const;

// The code value is found inside GAM ad unit settings tab.
type AdUnitCode =
  | "mobile-header-1"
  | "desktop-column-atf-0"
  | "desktop-column-btf-0"
  | "Desktop-Rightrail-0"
  | "desktop-rightrail-2nd-0"
  | "Mobile-Single-0"
  | "mobile-single-2nd-0"
  | "Desktop-SingleLeft-0"
  | "desktop-singleleft-2nd-0"
  | "desktop-tp-routepane-0"
  | "desktop-tp-routepane-2nd-0"
  | "desktop-tp-segmentpane-0"
  | "desktop-tp-segmentpane-baseline-0"
  | "desktop-tp-segmentpane-2nd-0"
  | "desktop-tp-map-0"
  | "desktop-inline-serp-0"
  | "inline-flights-0"
  | "interstitial-operator-0";

// Represents the configuration for each ad slot.
export type SlotConfig = {
  // adUnitCode - The "code" value of the ad unit which should be used.
  // This value is found inside GAM > Ad units > Ad unit: {name} > Settings.
  adUnitCode: AdUnitCode;
  // divId - The id we want to give to the div element that the ad will be rendered into.
  // It should be closely aligned to the ad unit name for easy identification.
  divId: string;
  // sizes - The size(s) of the ads. Can be a single size or an array of sizes.
  // It can also use "fluid" which takes 100% width of the parent and
  // resizes its height to fit creative content.
  sizes: googletag.GeneralSize;
  // sizeMapping - Maps which ad(s) to show based on the minimum viewport. It is an array of arrays,
  // where each inner array has two elements: a minimum viewport size and an array of ad
  // sizes to serve when the viewport is that size or larger. If an ad should not render
  // until hitting a minimum viewport (eg: 1260px), the size mapping should include `[[0, 0], []]`.
  sizeMapping?: googletag.SizeMappingArray;
  // tiers - Used in TieredDisplayAd.tsx (also known as waterfall bidding ads).
  // Enables the ad to be called multiple times at a decreasing bid rate until the unit is filled.
  // Usually includes "High", "Mid", "Low" and finally "Basement".
  tiers: string[];
  // adUnitSubersion - Used in TieredDisplayAd.tsx (also known as waterfall bidding ads).
  // Enables multiple variants of the one ad unit. By default,
  // the ad unit sub version should be 0.
  adUnitSubVersion: 0 | 1;
};

export type SlotConfigId = (typeof SlotConfigIds)[number];

export type SlotConfigMap = {
  [slotId in SlotConfigId]: SlotConfig;
};

export const slotConfigMap: SlotConfigMap = {
  mobileHeader: {
    adUnitCode: "mobile-header-1",
    divId: "mobile-header-1",
    sizes: [
      [300, 50],
      [320, 50],
    ],
    tiers: [],
    adUnitSubVersion: 0,
  },
  desktopColumn: {
    adUnitCode: "desktop-column-atf-0",
    divId: "desktop-column1-ad",
    sizes: [
      [300, 600],
      [300, 250],
    ],
    tiers: [],
    adUnitSubVersion: 0,
  },
  desktopColumn2: {
    adUnitCode: "desktop-column-btf-0",
    divId: "desktop-column2-ad",
    sizes: [
      [300, 600],
      [300, 250],
    ],
    tiers: [],
    adUnitSubVersion: 0,
  },
  rightRail: {
    adUnitCode: "Desktop-Rightrail-0",
    divId: "rightrail-ad1",
    sizes: [
      [160, 600],
      [300, 600],
    ],
    // sizeMapping is used to serve ads of different sizes based on the viewport
    // e.g when viewport width ≥ 1740px serve 300x600 sized ads, when ≥ 1260px, 160x600
    sizeMapping: [
      [[breakpoints.xxl, 0], [[300, 600]]],
      [[breakpoints.lg, 0], [[160, 600]]],
      [[0, 0], []],
    ],
    tiers: ["High", "Mid", "Low", "Basement"],
    adUnitSubVersion: 0,
  },
  rightRail2: {
    adUnitCode: "desktop-rightrail-2nd-0",
    divId: "rightrail-ad2",
    sizes: [
      [160, 600],
      [300, 600],
    ],
    sizeMapping: [
      [[breakpoints.xxl, 0], [[300, 600]]],
      [[breakpoints.lg, 0], [[160, 600]]],
      [[0, 0], []],
    ],
    tiers: ["High", "Mid", "Low", "Basement"],
    adUnitSubVersion: 0,
  },
  rightRailVariant: {
    adUnitCode: "Desktop-Rightrail-0",
    divId: "rightrail-ad1",
    sizes: [
      [160, 600],
      [300, 600],
    ],
    // sizeMapping is used to serve ads of different sizes based on the viewport
    // e.g when viewport width ≥ 1440px serve 300x600 sized ads, when ≥ 1260px, 160x600
    sizeMapping: [
      [[breakpoints.xl, 0], [[300, 600]]],
      [[breakpoints.lg, 0], [[160, 600]]],
      [[0, 0], []],
    ],
    tiers: ["High", "Mid", "Low", "Basement"],
    adUnitSubVersion: 0,
  },
  rightRail2Variant: {
    adUnitCode: "desktop-rightrail-2nd-0",
    divId: "rightrail-ad2",
    sizes: [
      [160, 600],
      [300, 600],
    ],
    sizeMapping: [
      [[breakpoints.xl, 0], [[300, 600]]],
      [[breakpoints.lg, 0], [[160, 600]]],
      [[0, 0], []],
    ],
    tiers: ["High", "Mid", "Low", "Basement"],
    adUnitSubVersion: 0,
  },
  singleMobile: {
    adUnitCode: "Mobile-Single-0",
    divId: "mobile-ad-box",
    sizes: [
      [336, 280],
      [300, 250],
      [320, 50],
      [300, 600],
    ],
    tiers: ["High", "Mid", "Low", "Basement"],
    adUnitSubVersion: 0,
  },
  singleMobile2: {
    adUnitCode: "mobile-single-2nd-0",
    divId: "mobile-ad-box2",
    sizes: [
      [336, 280],
      [300, 250],
      [350, 50],
    ],
    tiers: [],
    adUnitSubVersion: 0,
  },
  singleDesktop: {
    adUnitCode: "Desktop-SingleLeft-0",
    divId: "desktop-pane-ad",
    sizes: [
      [300, 600],
      [336, 280],
      [300, 250],
    ],
    tiers: ["High", "Mid", "Low", "Basement"],
    adUnitSubVersion: 0,
  },
  singleDesktop2: {
    adUnitCode: "desktop-singleleft-2nd-0",
    divId: "desktop-pane-ad2",
    sizes: [
      [336, 280],
      [300, 250],
    ],
    tiers: [],
    adUnitSubVersion: 0,
  },
  routeScreen: {
    adUnitCode: "desktop-tp-routepane-0",
    divId: "desktop-routepane-ad",
    sizes: [
      [300, 600],
      [300, 250],
    ],
    tiers: ["High", "Mid", "Low", "Basement"],
    adUnitSubVersion: 0,
  },
  routeScreen2: {
    adUnitCode: "desktop-tp-routepane-2nd-0",
    divId: "desktop-routepane-ad2",
    sizes: [[300, 250]],
    tiers: [],
    adUnitSubVersion: 0,
  },
  segmentScreen: {
    adUnitCode: "desktop-tp-segmentpane-0",
    divId: "desktop-segmentpane-ad",
    sizes: [[728, 90]],
    tiers: [],
    adUnitSubVersion: 0,
  },
  segmentScreenBaseline: {
    adUnitCode: "desktop-tp-segmentpane-baseline-0",
    divId: "desktop-segmentpane-ad",
    sizes: [[300, 250]],
    tiers: [],
    adUnitSubVersion: 0,
  },
  segmentScreenBanner: {
    adUnitCode: "desktop-tp-segmentpane-2nd-0",
    divId: "desktop-segmentpane-banner-ad",
    sizes: [
      [300, 250],
      [300, 600],
      [728, 90],
    ],
    sizeMapping: [
      [[1260, 0], [[728, 90]]],
      [
        [breakpoints.md, 0],
        [
          [300, 250],
          [300, 600],
        ],
      ],
      [[0, 0], []],
    ],
    tiers: [],
    adUnitSubVersion: 0,
  },
  segmentScreenTower: {
    adUnitCode: "desktop-tp-map-0",
    divId: "desktop-segmentpane-tower-ad",
    sizes: [
      [300, 250],
      [300, 600],
    ],
    sizeMapping: [
      [
        [breakpoints.md, 0],
        [
          [300, 250],
          [300, 600],
        ],
      ],
      [[0, 0], []],
    ],
    tiers: [],
    adUnitSubVersion: 0,
  },
  desktopInlineSerpAd: {
    adUnitCode: "desktop-inline-serp-0",
    divId: "desktop-inline-serp",
    sizes: "fluid",
    tiers: [],
    adUnitSubVersion: 0,
  },
  inlineSegmentAd: {
    adUnitCode: "inline-flights-0",
    divId: "desktop-inline-segment",
    sizes: "fluid",
    tiers: [],
    adUnitSubVersion: 0,
  },
  interstitialAd: {
    adUnitCode: "interstitial-operator-0",
    divId: "interstitial-operator",
    sizes: [[300, 250]],
    tiers: [],
    adUnitSubVersion: 0,
  },
};
