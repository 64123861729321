import { useIntl } from "react-intl";

import { useNavigateToHotelsPage } from "src/utils/hooks/useNavigateToHotelsPage";
import type { GeocodedPlace } from "src/PrefetchData";
import { HotelAlternative } from "src/svg/HotelAlternative";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { useExitOnHExEntry } from "src/domain/HotelsScreen/utils-exit-to-provider";
import { useFeature } from "src/feature/useFeature";
import type { AnalyticsEventCategory } from "src/analytics/analyticsEventTypes";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { useScreenSize } from "src/utils/hooks/useScreenSize";
import messages from "../TripHotelMapPin/TripHotelMapPin.messages";
import { PanePromoButton } from "../PanePromoButton/PanePromoButton";

export function HotelsPromo({
  location,
  eventCategory = "SearchResults",
}: {
  location: GeocodedPlace;
  eventCategory?: AnalyticsEventCategory;
}) {
  const handleExpediaExit = useExitOnHExEntry("Expedia");
  const [newTabSERPExperience, returnToCore, hexIsExpedia] = [
    useFeature("NewTabSERPExperience"),
    useFeature("ReturnToCore"),
    useFeature("ExpediaOnEntry"),
  ];
  const typedLocation = useTypedLocation();
  const isDesktopScreen = useScreenSize() === "large";
  const shouldOpenInNewTab =
    newTabSERPExperience &&
    returnToCore &&
    typedLocation?.state?.highlightedTab !== "trips" &&
    isDesktopScreen;

  const intl = useIntl();
  const { navigateToHotels } = useNavigateToHotelsPage();

  const promoText = intl.formatMessage(messages.hotelsIn, {
    location: location.shortName,
  });
  const tagline = intl.formatMessage(messages.compare);

  return (
    <PanePromoButton
      onClick={() => {
        sendAnalyticsInteractionEvent({
          category: eventCategory,
          action: "Click:HotelPromoSingleDest",
        });

        if (hexIsExpedia && handleExpediaExit) {
          handleExpediaExit({
            lat: location?.lat,
            lng: location?.lng,
            canonicalName: location?.canonicalName,
          });
        } else {
          navigateToHotels(undefined, shouldOpenInNewTab);
        }
      }}
      promoText={promoText}
      tagline={tagline}
    >
      <HotelAlternative tint="cod" />
    </PanePromoButton>
  );
}
